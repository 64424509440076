import { createEnv } from '@t3-oss/env-nextjs'
import { z } from 'zod'

export const env = createEnv({
  server: {
    NODE_ENV: z.enum(['production', 'development', 'test']).optional(),
    E2E_BASE_URL: z.string().optional(),
    E2E_USERNAME: z.string().optional(),
    E2E_PASSWORD: z.string().optional(),
    ANALYZE: z.enum(['true', 'false']).optional(),
  },
  client: {
    NEXT_PUBLIC_API_ROOT: z.string(),
    NEXT_PUBLIC_BASE_URI: z.string(),
    NEXT_PUBLIC_ENV: z.enum(['LOCAL', 'PRE', 'PRO']),
    NEXT_PUBLIC_X_CLIENT_ID: z.string(),
    NEXT_PUBLIC_SSO_CLIENT_ID: z.string(),
    NEXT_PUBLIC_SSO_REDIRECT_URI: z.string(),
    NEXT_PUBLIC_SSO_APP_ID: z.string(),
    NEXT_PUBLIC_SSO_SCOPES: z.string(),
    NEXT_PUBLIC_SAS_TOKEN: z.string().optional(),
    NEXT_PUBLIC_SAS_TOKEN_HISTORICAL: z.string().optional(),
    NEXT_PUBLIC_SENTRY_DSN: z.string(),
    NEXT_PUBLIC_SENTRY_ENVIRONMENT: z.enum([
      'local',
      'development',
      'production',
    ]),
    NEXT_PUBLIC_SENTRY_ORG: z.literal('ingka'),
    NEXT_PUBLIC_SENTRY_PROJECT: z.literal('as-is-shop-manager'),
  },
  runtimeEnv: {
    NODE_ENV: process.env.NODE_ENV,
    NEXT_PUBLIC_ENV: process.env.NEXT_PUBLIC_ENV,
    NEXT_PUBLIC_BASE_URI: process.env.NEXT_PUBLIC_BASE_URI,
    // root url for api calls
    NEXT_PUBLIC_API_ROOT: process.env.NEXT_PUBLIC_API_ROOT,
    // auth variables
    NEXT_PUBLIC_SSO_CLIENT_ID: process.env.NEXT_PUBLIC_SSO_CLIENT_ID,
    NEXT_PUBLIC_SSO_REDIRECT_URI: process.env.NEXT_PUBLIC_SSO_REDIRECT_URI,
    NEXT_PUBLIC_SSO_APP_ID: process.env.NEXT_PUBLIC_SSO_APP_ID,
    NEXT_PUBLIC_SSO_SCOPES: process.env.NEXT_PUBLIC_SSO_SCOPES,
    NEXT_PUBLIC_X_CLIENT_ID: process.env.NEXT_PUBLIC_X_CLIENT_ID,
    // sentry
    NEXT_PUBLIC_SENTRY_ENVIRONMENT: process.env.NEXT_PUBLIC_SENTRY_ENVIRONMENT,
    NEXT_PUBLIC_SENTRY_DSN: process.env.NEXT_PUBLIC_SENTRY_DSN,
    NEXT_PUBLIC_SENTRY_ORG: process.env.NEXT_PUBLIC_SENTRY_ORG,
    NEXT_PUBLIC_SENTRY_PROJECT: process.env.NEXT_PUBLIC_SENTRY_PROJECT,
    // e2e testing
    E2E_BASE_URL: process.env.E2E_BASE_URL,
    E2E_USERNAME: process.env.E2E_USERNAME,
    E2E_PASSWORD: process.env.E2E_PASSWORD,
    // bundle analyzer
    ANALYZE: process.env.ANALYZE,
    // sas images
    NEXT_PUBLIC_SAS_TOKEN: process.env.NEXT_PUBLIC_SAS_TOKEN,
    NEXT_PUBLIC_SAS_TOKEN_HISTORICAL:
      process.env.NEXT_PUBLIC_SAS_TOKEN_HISTORICAL,
  },
})
