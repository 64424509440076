// have to import the json and then make it a const to have type safety
import defaultTranslation from '../public/locales/en/translation.json'
export const defaultNS = 'translation' as const
export const defaultResource = {
  ...{ [defaultNS]: defaultTranslation },
} as const

declare module 'i18next' {
  interface CustomTypeOptions {
    resources: typeof defaultResource
  }
}

export enum LanguageCode {
  'ca-ES' = 'ca-ES',
  'cs-CZ' = 'cs-CZ',
  'da-DK' = 'da-DK',
  'de-AT' = 'de-AT',
  'de-CH' = 'de-CH',
  'de-DE' = 'de-DE',
  'en-AU' = 'en-AU',
  'en-BE' = 'en-BE',
  'en-CA' = 'en-CA',
  'en-CH' = 'en-CH',
  'en-CN' = 'en-CN',
  'en-ES' = 'en-ES',
  'en-GB' = 'en-GB',
  'en-IE' = 'en-IE',
  'en-IN' = 'en-IN',
  'en-JP' = 'en-JP',
  'en-KR' = 'en-KR',
  'en-NL' = 'en-NL',
  'en-PT' = 'en-PT',
  'en-SE' = 'en-SE',
  'en-US' = 'en-US',
  en = 'en',
  'es-ES' = 'es-ES',
  'eu-ES' = 'eu-ES',
  'fi-FI' = 'fi-FI',
  'fr-BE' = 'fr-BE',
  'fr-CA' = 'fr-CA',
  'fr-CH' = 'fr-CH',
  'fr-FR' = 'fr-FR',
  'gl-ES' = 'gl-ES',
  'hr-HR' = 'hr-HR',
  'hu-HU' = 'hu-HU',
  'it-CH' = 'it-CH',
  'it-IT' = 'it-IT',
  'ja-JP' = 'ja-JP',
  'ko-KR' = 'ko-KR',
  'nl-BE' = 'nl-BE',
  'nl-NL' = 'nl-NL',
  'no-NO' = 'no-NO',
  'pl-PL' = 'pl-PL',
  'pt-PT' = 'pt-PT',
  'ro-RO' = 'ro-RO',
  'sk-SK' = 'sk-SK',
  'sl-SI' = 'sl-SI',
  'sr-RS' = 'sr-RS',
  'sv-SE' = 'sv-SE',
  'zh-CN' = 'zh-CN',
}

export enum BackendLanguageCode {
  cs = 'cs',
  da = 'da',
  de = 'de',
  en = 'en',
  es = 'es',
  fi = 'fi',
  fr = 'fr',
  hr = 'hr',
  hu = 'hu',
  it = 'it',
  ja = 'ja',
  ko = 'ko',
  nl = 'nl',
  no = 'no',
  pl = 'pl',
  pt = 'pt',
  ro = 'ro',
  sr = 'sr',
  sk = 'sk',
  sl = 'sl',
  sv = 'sv',
  zh = 'zh',
}
