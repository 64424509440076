import type { Article } from '~/schema/article'

export function calculateArticlesPrice(
  articles: Article[],
  countryCode?: string,
  langCode?: string,
) {
  if (!countryCode || !langCode) return '0'

  const prices = articles.reduce((total, article) => {
    if (
      article.articleTranslations?.length &&
      article.qty &&
      article.articleTranslations[0].price
    ) {
      return total + article.qty * article.articleTranslations[0].price
    }
    return total
  }, 0)

  const currency = articles[0]?.currency_code || 'EUR'

  const formattedPrice = prices.toLocaleString(
    `${languageCodeFromUrlCode(langCode)}-${countryCode}`,
    {
      style: 'currency',
      currency,
    },
  )

  return formattedPrice
}

export function formatPrice(
  price: number,
  articles: Article[],
  countryCode?: string,
  langCode?: string,
) {
  if (!countryCode || !langCode) return '0'

  // FIXME: how to deal with unset currency codes?
  const currency = articles[0]?.currency_code || 'EUR'
  const formattedPrice = price.toLocaleString(
    `${languageCodeFromUrlCode(langCode)}-${countryCode}`,
    {
      style: 'currency',
      currency,
    },
  )

  return formattedPrice
}

export const languageCodeFromUrlCode = (urlCode: string) =>
  urlCode.split('-')[0]
